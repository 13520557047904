import showPass from "./show-pass";
import fancybox from "./fancybox";
import rangeSlider from './range-slider';
import theme from './theme';
import phonemask from "./phonemask";
import ymaps from "./ymaps";
import scrollTo from "./scrollTo";
import tab from 'npm-kit-tab';
import toggle from 'npm-kit-toggle';
import ripple from '@qpokychuk/ripple';
import Swiper, { Navigation, Pagination, Scrollbar, Autoplay, Grid, Thumbs, EffectFade, Lazy } from 'swiper';
import { PageFlip } from 'page-flip';

import '../scss/index.scss';

Swiper.use([Navigation, Pagination, Scrollbar, Autoplay, Grid, Thumbs, EffectFade, Lazy]);
Swiper.defaults.touchStartPreventDefault = false
window.Swiper = Swiper
window.ripple = ripple
window.addEventListener('DOMContentLoaded', () => loadHandler())

function loadHandler() {
	fancybox.init()
	showPass.init()
	scrollTo.init()
	rangeSlider.init()
	tab.init()
	toggle.init()
	ripple.init()
	theme.init()
	ymaps.init()
	phonemask.init('[type="tel"]')

	ripple.attach('.btn')
	ripple.attach('.waved')
	ripple.deAttach('.btn-text')

	const $bookParentElement = document.getElementById('book-slider')
	const pageFlip = new PageFlip($bookParentElement, {
		width: 552,
		height: 774,
		maxShadowOpacity: 0.5,
		size: 'stretch',
		minWidth: 270,
		maxWidth: 552,
		minHeight: 300,
		maxHeight: 774,
		flippingTime: 750
	});

	function checkSliderButtons() {
		document.querySelector('.book-slider-prev').disabled = pageFlip.getCurrentPageIndex() === 0
		document.querySelector('.book-slider-next').disabled = (pageFlip.getPageCount() - 1) === pageFlip.getCurrentPageIndex()
	}

	document.addEventListener('click', (event) => {
		if (event.target.closest('.book-slider-prev')) {
			pageFlip.flipPrev()
		}
		if (event.target.closest('.book-slider-next')) {
			pageFlip.flipNext()

		}
	})

	const $text = []
	const $images = []
	let $sortedPages = []
	const $pages = Array.from($bookParentElement.querySelectorAll('.book-page'))
		.filter(($page) => {
			return getComputedStyle($page).display !== 'none'
		})

	if ($pages.length !== $bookParentElement.querySelectorAll('.book-page').length) {
		$pages.forEach(($page) => {
			if ($page.classList.contains('book-page-image')) {
				$images.push($page)
			} else {
				$text.push($page)
			}
		})

		$text.forEach(($page, index) => {
			if (index !== 0 && (index) % 4 === 0 && $images.length > 0) {
				$sortedPages.push($images.shift())
			}
			$sortedPages.push($page)

		})
	} else {
		$sortedPages = $pages
	}

	if ($images.length > 0) {
		$sortedPages = [
			...$sortedPages,
			...$images
		]
	}


	pageFlip.loadFromHTML($sortedPages);
	pageFlip.on('flip', checkSliderButtons)
	checkSliderButtons()
}